/*  Theming colors  */
:root {
  --success-green: #4caf50;
  --navigo-color: #407ad6;
  --red: #e15845;
  --red-rgba: 225, 88, 69;
  --error-red: #f44336;
  --input-border: #666f7c;
  --tableRowBackgroundColor: #1c2737;
  --selectedTableRowBackgroundColor: #233044;
  --table-border-color: rgba(81, 81, 81, 1);
  --primaryColor: #3498db;
  --root-bg: #1b2635;
  --secondary-color: #233044;
  --background-color: #233044;
  --node-color: #454f5b;
  --backdrop-color: rgba(0, 0, 0, 0.5);
  --disabled-grey: #8080802b;
  --primary-color-light: #c8d5eb;
}

.ihquVm {
  padding: 24px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* ::-webkit-scrollbar {
  display: none;
} */

* {
  scrollbar-width: thin;
  scrollbar-color: #aaa;
  word-break: break-word;
}
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  /* background: #f5f5f5; */
  border-radius: 20px;
}
*::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 20px;
  /* border: 3px solid #f5f5f5; */
}

.MuiAutocomplete-listbox,
.MuiMenu-list {
  max-height: 28vh;
  overflow: auto;
  border: 2px solid var(--navigo-color);
  /* border-top: 0; */
}

@media screen and (max-width: 700px) {
  .MuiAutocomplete-listbox,
  .MuiMenu-list {
    border-top: 2px solid var(--navigo-color);
  }
}

/* style specific to custom popup */
.custom-popup-comp > .MuiDialog-scrollPaper > div {
  min-width: 350px;
  max-width: 60vw;
}

.caps-first-letter::first-letter {
  text-transform: uppercase;
}

iframe {
  /* display: none; */
}

.rc-time-picker-panel-select {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.rc-time-picker-panel-select::-webkit-scrollbar {
  display: none !important; /* Chrome and Safari */
}

.rc-time-picker-panel-select li {
  color: #233044 !important;
}

.arrow-rotate-animation {
  transition: rotate 0.3s ease-in-out;
}

.full-height-animation {
  max-height: 0px;
  transition: max-height 0.3s ease-in-out;
}

.full-height-animation.show {
  max-height: 1000px;
}
